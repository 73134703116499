import * as BABYLON from "@babylonjs/core";
// import webHeaderImage from './staket_v1/staket.babylon';


const newPathIsOn = true

const useLoadAsset = () => {
	const loadAsset = async ({ scene, confSetup, setState }) => {
		// const webHeaderImage = await import("./media/staket/staket.babylon");
		let path = process.env.PUBLIC_URL + confSetup.sceneSettings.babylonFile.path;
		const file = confSetup.sceneSettings.babylonFile.file;

		var assetsManager = new BABYLON.AssetsManager(scene);
		assetsManager.useDefaultLoadingScreen = false;
		assetsManager.onFinish = function(tasks) {};

		if (!newPathIsOn) var meshTask = assetsManager.addMeshTask("skull task", "", path, file);
// 
		const file2 = confSetup.sceneSettings.babylonFile2.file;
		const path2 = confSetup.sceneSettings.babylonFile2.path;

		const babylonFile = () => require(`${path2 + file2}`);

		if (newPathIsOn) {
		// NEW CODE
		var meshTask = assetsManager.addMeshTask(
			"skull task",
			"",
			// require(`./media/cabinet/cabinet.babylon`),
			// require(`${path2 + file2}`),
			// require(path2 + file),
			babylonFile(),
			"",
		);
		};
		// NEW CODE

		meshTask.onSuccess = function(task) {
			task.loadedMeshes.map((item) => {
				item.isVisible = false;
			});

			if (newPathIsOn) {
				
				// NEW CODE
				const checkTexture = (material, texture) => {
					if (material[texture] !== null) {
						// console.log("-------------------------------------");
						// console.log(material[texture].name);
						const file55 = material[texture].name;
						// console.log(texture + "  " + path2 + file55);
					// console.log(require(`${path2 + file55}`))
					material[texture] = new BABYLON.Texture(require(`${path2 + file55}`));
					if (file55.includes("shadow")) {
						material.albedoTexture.hasAlpha = true;
						material.useAlphaFromAlbedoTexture = true;
					}
					if (file55.includes("stolpe")) {
						// console.log("file55")
						// console.log(file55)
						
					}
				}
			};

			// console.log(scene.materials)
			for (let index = 0; index < scene.materials.length; index++) {
				const material = scene.materials[index];
				// console.log(material.name);
				// console.log(material);
				checkTexture(material, 'reflectionTexture')
				checkTexture(material, "albedoTexture");
				checkTexture(material, "bumpTexture");
				checkTexture(material, "metallicTexture");
				// checkTexture(material, "diffuseTexture");
			}
			// NEW CODE
		}

			setState((prev) => ({ ...prev, assetIsReady: true }));
		};

		meshTask.onError = function(task) {
			// console.log("ERROR - MISSING 3D FILE", task);
		};
		assetsManager.load();
	};

	return loadAsset;
};

export default useLoadAsset;

// console.log(uniqByKeepFirst(data, it => it.u))
// console.log(uniqByKeepLast(data, it => it.u))

// function uniqByKeepFirst(a, key) {
//   let seen = new Set();
//   return a.filter((item) => {
//     let k = key(item);
//     return seen.has(k) ? false : seen.add(k);
//   });
// }

// function uniqByKeepLast(a, key) {
// 	return [...new Map(a.map((x) => [key(x), x])).values()];
// }

// https://dev.to/vinodchauhan7/react-hooks-with-async-await-1n9g
// https://www.freecodecamp.org/news/how-to-create-react-hooks/
// https://ysfaran.github.io/blog/post/0002-use-state-with-promise/
