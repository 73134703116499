import React, { useState, useEffect, useRef } from "react";
import "../style.css";
import { MeshOptions, AnimateOptions, MaterialOptions, Menu55 } from "./comp/Options";
import { addLight } from "./func/addLight";
import { addDraggableMesh } from "./func/addDraggableMesh";

export default function SceneOptions({ mainScene, state }) {
	const confSetup = state.confSetup;
	useEffect(() => {
		// mainScene.getEngine().resize();
		confSetup.lights.map((light) => {
			addLight({ light, mainScene, confSetup });
		});

		confSetup.focusPoints.map((item) => {
			addDraggableMesh({ item, mainScene, confSetup });
		});
	}, []);

	return (
		<div className={`overlay ${state.focusPoint}`}>
			{/* <label>{confSetup.header}</label> */}
			<Menu55 mainScene={mainScene} confSetup={confSetup} />

			{/* <MaterialOptions mainScene={mainScene} confSetup={confSetup} /> */}
			{/* <MeshOptions mainScene={mainScene} confSetup={confSetup} type={"meshGroups"} /> */}
			{/* <AnimateOptions mainScene={mainScene} confSetup={confSetup} type={"animations"} /> */}
		</div>
	);
}



// const menu55 = () => {
// 	return confSetup.menu55.map((group) => (
// 		<ButtonColorControl
// 			key={group}
// 			type={group}
// 			actionHandler={changeMaterialHandler}
// 			confSetup={confSetup}
// 			active={confSetup[group + "ActiveGroup"]}
// 		/>
// 	));
// };
