import React, { useState, useEffect, useRef } from "react";
import "../../style.css";

export function getLang(key, confSetup) {
	const textString = confSetup.translate[key];
	return textString !== undefined ? textString : key;
}

export function ButtonControl({ type, actionHandler, confSetup }) {
	return (
		<div>
			{getLang(type, confSetup)} :
			{confSetup[type].map((item) => (
				<button
					className='optionButton'
					key={item.name}
					onClick={() => {
						actionHandler(item);
					}}>
					{getLang(item.name, confSetup)}
				</button>
			))}
		</div>
	);
}

export function ButtonColorControl({ type, actionHandler, confSetup }) {
	const [state, setState] = useState(confSetup[type + "ActiveGroup"]);
	return (
		<div className='colour-selection'>
			<span>{getLang(type, confSetup)}</span>
			{confSetup[type].map((item, index) => (
				<button
					className={`${item.name === state ? "active" : ""} ${item.name}`}
					key={item.name}
					onClick={() => {
						actionHandler(item, type);
						setState(item.name);
					}}></button>
			))}
		</div>
	);
}

export function SwitchControl({ type, actionHandler, confSetup }) {
	const [state, setState] = useState(false);
	return (
		<div>
			{type} :
			{confSetup[type].map((item) => (
				<label key={item.name} className='switch2'>
					<input
						type='checkbox'
						checked={state}
						onChange={(e) => {
							setState(!state);
							actionHandler(item);
						}}
					/>
					<span className='slider2 round' />
				</label>
			))}
		</div>
	);
}

export function SliderControl({ type, actionHandler, confSetup }) {
	const ref = useRef();
	const index = confSetup[type].findIndex((item) => item.name === confSetup[type + "ActiveGroup"]);
	const [value, setValue] = useState(index);

	useEffect(() => {
		style_slider();
		style_slider_update();
		return () => {
			style_slider();
			style_slider_update();
		};
	});

	return (
		<div className='length-content'>
			<div ref={ref} className='length-selection'>
				<input
					type='range'
					list='lengths'
					value={value}
					max={confSetup[type].length - 1}
					min={0}
					step={1}
					onChange={(e) => {
						actionHandler(confSetup[type][e.target.value]);
						setValue(e.target.value);
						// setUpdate(Date.now());
					}}
				/>
				<div id='lengths'>
					{confSetup[type].map((option, index) => (
						<span key={index} id={index} value={index}>
							{getLang(option.name, confSetup)}
						</span>
					))}
				</div>
				<output />
			</div>
		</div>
	);

	// return (
	// 	<div className='slidecontainer'>
	// 		{type} :
	// 		<input
	// 			type='range'
	// 			min={0}
	// 			max={confSetup[type].length - 1}
	// 			defaultValue={1}
	// 			className='slider'
	// 			id='myRange'
	// 			onChange={(e) => {
	// 				actionHandler(confSetup[type][e.target.value]);
	// 			}}
	// 		/>
	// 	</div>
	// );
}

function style_slider_update() {
	document.querySelectorAll(".length-selection").forEach(function(ctrl) {
		var el = ctrl.querySelector("input");
		el.oninput();
	});
}

function style_slider() {
	/* Range slider Length */
	document.querySelectorAll(".length-selection").forEach(function(ctrl) {
		var el = ctrl.querySelector("input");
		var dl = ctrl.querySelector("#lengths");
		var output = ctrl.querySelector("output");

		el.oninput = function() {
			// colorize step options
			ctrl.querySelectorAll("span").forEach(function(opt) {
				if (opt.id <= el.valueAsNumber) opt.style.backgroundColor = "#E4CC7A";
				else opt.style.backgroundColor = "#E3E3E3";
			});
			var valPercent =
				(el.valueAsNumber - parseInt(el.min)) / (parseInt(el.max) - parseInt(el.min));
			var style =
				"background-image: -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(" +
				valPercent +
				", #E4CC7A), color-stop(" +
				valPercent +
				", #E3E3E3));";
			dl.style = style;
			// Popup
			if ((" " + ctrl.className + " ").indexOf(" " + "length-selection" + " ") > -1) {
				var selectedOpt = ctrl.querySelector('span[value="' + el.value + '"]');
				//output.innerText = selectedOpt.text;
				output.innerText = selectedOpt.innerText;
				output.style.left = "50%";
				output.style.left =
					selectedOpt.offsetLeft + selectedOpt.offsetWidth / 2 - output.offsetWidth / 2 + "px";

				el.style.left = output.style.left;
			}
		};
		el.oninput();
	});
	window.onresize = function() {
		document.querySelectorAll(".length-selection").forEach(function(ctrl) {
			var el = ctrl.querySelector("input");
			el.oninput();
		});
	};
}
