import React, { useState, useEffect, useRef } from "react";
import "../style.css";
import { Cms } from "./comp/Cms";
import { addLight } from "./func/addLight";
import { addDraggableMesh } from "./func/addDraggableMesh";
import { cameraSettings } from "./babylonSetup/cameraSettings";

export default function SceneOptions({ mainScene, confSetup }) {
	const [settingsVisible, setSettingsVisible] = useState(false);

	useEffect(() => {
		mainScene.getEngine().resize();
	}, []);

	useEffect(() => {
		const listener = (event) => {
			if (event.code === "KeyW") {
				event.preventDefault();
				setSettingsVisible(!settingsVisible);
				mainScene.getEngine().resize();
			}
		};
		document.addEventListener("keydown", listener);

		return () => document.removeEventListener("keydown", listener);
	}, [settingsVisible]);

	return (
		<div>
			{settingsVisible && <Cms mainScene={mainScene} confSetup={confSetup} />}
		</div>
	);
}
