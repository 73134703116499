
import * as GUI from "@babylonjs/gui";
import * as BABYLON from "@babylonjs/core";


export function guiColorPicker(material) {
    

  // GUI
  var advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");

  var panel = new GUI.StackPanel();
  panel.width = "400px";
  panel.isVertical = true;
  panel.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
  panel.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
  advancedTexture.addControl(panel);

  var textBlock = new GUI.TextBlock();
  textBlock.text = "Diffuse color:";
  textBlock.height = "30px";
  panel.addControl(textBlock);     


  var picker = new GUI.ColorPicker();
  picker.value = material.albedoColor;
  picker.height = "400px";
  picker.width = "400px";
  picker.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
  picker.onValueChangedObservable.add(function(value) { // value is a color3
      material.albedoColor.copyFrom(value);
  });

  panel.addControl(picker);    
  
}
