export default {
	sceneSettings: {
		babylonFile: { path: "/babylon/cabinet/", file: "cabinet.babylon" },
		babylonFile2: { path: "./media/cabinet/", file: "cabinet.babylon" },
		envPath: "/babylon/env/",
		envFiles: [
			"studio.env",
			"studio_small_08_2k.env",
			"photo_studio_01_2k.env",
			"flowerRoad_clamped.env",
			"frozen_lake_4k.env",
		],
		envActive: "studio.env",
		envdRotationY: 159,
		contrast: 1.22,
		exposure: 0.71,
		intesity: 1.74,
	},
	cameraSettings: {
		lowerAlphaLimit: -180 * 5, // ROTATE LIMIT
		upperAlphaLimit: 180 * 5, // ROTATE LIMIT
		lowerBetaLimit: 10, // TILT UP LIMIT
		upperBetaLimit: 95, // TILT DOWN LIMIT
		lowerRadiusLimit: 0.1, // ZOOM IN LIMIT
		upperRadiusLimit: 10, // ZOOM OUT LIMIT
		wheelDeltaPercentage: 0.00001, // ZOOM SPEED
		pinchDeltaPercentage: 0.00001, // ZOOM SPEED
		pinchToPanMaxDistance: 0,
		pinchPrecision: 0,
		panningSensibility: 0.001, // PANNING SPEED 0 : off
		panningDistanceLimit: 0.01,
		minZ: 0.1, // VIEW 3D NEARBY
		maxZ: 15, // VIEW 3D FAR DISTANCE
		fov: 0.6, // FIELD OF VIEW
		useAutoRotationBehavior: false, // AUTO ROTATE CAMERA
		viewport: [0.0, 0.0, 1.0, 1.0], // CENTER VIEW
	},

	uiSettings: {
		// guiNavButtons: true,
		// materials: true,
		// optionsSlider: true,
		// optionsButtons: true,
		// settingsButton: true,
		// materialsButton: true,
		// materialsSlider: true,
		// animationButtons: false,
		// animationSlider: false,
		// animationSwitch: true,
		// combActionButton: true,
		//////////////////
		guiNavButtons: true,
		materials: false,
		optionsSlider: false,
		optionsButtons: false,
		settingsButton: false,
		materialsButton: false,
		materialsSlider: false,
		animationButtons: false,
		animationSlider: false,
		animationSwitch: false,
		combActionButton: false,
	},
	header: "Lås upp eller zooma",
	active: {
		optionalMeshes: "",
		focusPoints: "focusPoint_default",
		materials: "white",
	},
	meshGroupsActiveGroup: "#_item_1",
	meshGroups: [
		{
			name: "#_item_1",
			target: ["#_item_1"],
			active: true,
		},
	],
	isReady: true,

	lights: [
		{
			name: "light_A",
			type: "PointLight",
			intensity: 5,
			position: { x: 1.11, y: 0.77, z: -0.06 },
			diffuse: { r: 1, g: 1, b: 1 },
			specular: { r: 0.1, g: 0.1, b: 0.1 },
		},
		{
			name: "light_B",
			type: "PointLight",
			intensity: 1.12,
			position: { x: -0.33, y: 0.24, z: 0.59 },
			diffuse: { r: 1, g: 1, b: 1 },
			specular: { r: 0.1, g: 0.1, b: 0.1 },
		},
		{
			name: "light_C",
			type: "PointLight",
			intensity: 1.13,
			position: { x: 0.07, y: 0.28, z: -0.58 },
			diffuse: { r: 1, g: 1, b: 1 },
			specular: { r: 0.1, g: 0.1, b: 0.1 },
		},
		{
			name: "light_D",
			type: "PointLight",
			intensity: 0.82,
			position: { x: 0.02, y: 0.89, z: 0.04 },
			diffuse: { r: 1, g: 1, b: 1 },
			specular: { r: 0.1, g: 0.1, b: 0.1 },
		},
	],
	startAction: "start",
	actions: [
		{
			name: "start",
			actions: [
				{
					focusPoint: "focusPoint_default",
					animation: [],
				},
			],
		},
		{
			name: "start-back",
			actions: [
				{
					focusPoint: "focusPoint_default",
					animation: [],
				},
			],
		},
		{
			name: "rail",
			actions: [
				{
					focusPoint: "",
					animation: ["railMove1", "railMove2", "railMove3"],
				},
				{
					focusPoint: "focusPoint_rail",
					animation: [],
				},
			],
		},
		{
			name: "rail-back",
			actions: [
				{
					focusPoint: "focusPoint_default",
					animation: ["railMove1", "railMove2", "railMove3"],
				},
			],
		},
		{
			name: "lock",
			actions: [
				{
					focusPoint: "focusPoint_front",
					animation: ["#_item_1_look_move"],
				},
				{
					focusPoint: "",
					animation: ["animation_item_1", "move_right_wall", "move_left_wall"],
				},

				{
					focusPoint: "focusPoint_edge",
					animation: ["#_item_1_look_move"],
				},
			],
		},
		{
			name: "focusPoint_default",
			actions: [
				{
					focusPoint: "focusPoint_front",
					animation: ["#_item_1_look_move"],
				},
				{
					focusPoint: "",
					animation: ["animation_item_1", "move_right_wall", "move_left_wall"],
				},

				{
					focusPoint: "focusPoint_edge",
					animation: ["#_item_1_look_move"],
				},
			],
		},
		{
			name: "lock-back",
			actions: [
				{
					focusPoint: "focusPoint_front",
					animation: [
						"#_item_1_look_move",
						"animation_item_1",
						"move_right_wall",
						"move_left_wall",
					],
				},
				{
					focusPoint: "focusPoint_default",
					animation: ["#_item_1_look_move"],
				},
			],
		},
	],

	menu55: [
		{ id: "root", type: "group", childrenId: ["cabinet-text"] },
		{ id: "cabinet-text", type: "text", text: "Lås upp eller zooma" },
		// { id: "root", type: "group", childrenId: ["cabinet-text", "flex cabinet"] },
		{ id: "flex cabinet", type: "group", text: "df", childrenId: ["color"] },
		{
			id: "color",
			type: "material",
			ui: "ButtonColorControl",
			active: "_bag2-blue",
			text: "Byt färg på skåpet ",
			childrenId: ["mat_beige_right", "mat_beige_right2"],
		},
	],

	focusPointsVisible: ["focusPoint_edge", "focusPoint_rail"],
	focusPoints: [
		{
			name: "focusPoint_rail",
			target: "focusPoint_rail",
			action: "rail",
			return: "focusPoint_default",
			parent: "",
			lable: "img_zoom.svg",
			position: { x: 0.17, y: 0.28, z: 0.2 },
			scaling: 0.03,
			alpha: 23,
			beta: 95,
			radius: 0.68,
			mesh: "focusPoint_rail",
		},
		{
			name: "focusPoint_edge",
			target: "focusPoint_edge",
			return: "focusPoint_default",
			parent: "#_item_1_look",
			action: "lock",
			lable: "img_lock.svg",
			position: { x: 0.0, y: 0.0, z: 0 },
			alpha: 15,
			beta: 90,
			radius: 0.43,
			mesh: "focusPoint_edge",
			scaling: 0.13,
		},
		// {
		// 	name: "focusPoint_edge",
		// 	target: "focusPoint_edge",
		// 	return: "focusPoint_default",
		// 	parent: "#_item_1_look",
		// 	action: "lock",
		// 	lable: "2",
		// 	position: { x: 0, y: 0, z: 0 },
		// 	alpha: 35,
		// 	beta: 70,
		// 	radius: 0.43,
		// 	mesh: "focusPoint_edge",
		// 	scaling: 0.52,
		// },

		{
			name: "focusPoint_default",
			target: "focusPoint_default",
			return: "focusPoint_default",
			parent: "",
			action: "start",
			lable: "f_d",
			position: { x: -0.01, y: 0.24, z: 0 },
			scaling: 1,
			alpha: 28,
			beta: 80,
			radius: 1.64,
			mesh: "focusPoint_default",
		},
		{
			name: "focusPoint_front",
			target: "focusPoint_default",
			return: "focusPoint_default",
			parent: "",
			action: "start",
			lable: "f_f",
			position: { x: -0.01, y: 0.61, z: 0 },
			scaling: 1,
			alpha: 1,
			beta: 73,
			radius: 1.83,
			mesh: "focusPoint_front",
		},
	],
	// focusPoints: [
	// 	{
	// 		name: "rail",
	// 		target: "focusPoint_back2",
	// 		action: "rail",
	// 		lable: "1",
	// 		alpha: 35,
	// 		beta: 100,
	// 		radius: 0.5,
	// 	},

	// 	{
	// 		name: "focusPoint_edge",
	// 		target: "focusPoint_edge",
	// 		action: "lock",
	// 		lable: "2",
	// 		alpha: 35,
	// 		beta: 70,
	// 		radius: 0.5,
	// 	},
	// 	{
	// 		name: "focusPoint_default",
	// 		target: "focusPoint_default",
	// 		lable: "3",
	// 		alpha: -34,
	// 		beta: 74,
	// 		radius: 2,
	// 	},
	// 	{
	// 		name: "focusPoint_front",
	// 		target: "focusPoint_default",
	// 		lable: "4",
	// 		alpha: 0,
	// 		beta: 80,
	// 		radius: 2,
	// 	},
	// ],
	animations: [
		{
			name: "animation_item_1",
			target: "animation_item_1",
			animation: [
				{ type: "rotation", x: 0, y: 55, z: 0 },
				{ type: "position", x: 0, y: 0, z: 0 },
			],
		},
		{
			name: "#_item_1_look_move",
			target: "#_item_1_look_move",
			// target: ["#_item_1_look_move"],
			animation: [
				{ type: "rotation", x: -90, y: 0, z: 0 },
				{ type: "position", x: 0, y: 0, z: 0 },
				// { type: "scale", x: 0.1, y: 0, z: 0 },
			],
		},
		{
			name: "move_left_wall",
			target: "#_item_1_wall_left",
			animation: [
				{ type: "rotation", x: 0, y: 0, z: 0 },
				{ type: "position", x: 0, y: 0, z: 0.2 },
				// { type: "scale", x: 0.1, y: 0, z: 0 },
			],
		},
		{
			name: "railMove1",
			target: "#_item_1_wall_left",
			animation: [
				{ type: "rotation", x: 0, y: 0, z: 0 },
				{ type: "position", x: 0, y: -1, z: 0 },
				// { type: "scale", x: 0.1, y: 0, z: 0 },
			],
		},
		{
			name: "railMove2",
			target: "#_item_1_wall_right",
			animation: [
				{ type: "rotation", x: 0, y: 0, z: 0 },
				{ type: "position", x: 0, y: -1, z: 0 },
				// { type: "scale", x: 0.1, y: 0, z: 0 },
			],
		},
		{
			name: "railMove3",
			target: "animation_item_1",
			animation: [
				{ type: "rotation", x: 0, y: 0, z: 0 },
				{ type: "position", x: 0, y: -1, z: 0 },
				// { type: "scale", x: 0.1, y: 0, z: 0 },
			],
		},
		{
			name: "move_right_wall",
			target: "#_item_1_wall_right",
			animation: [
				{ type: "rotation", x: 0, y: 0, z: 0 },
				{ type: "position", x: 0, y: 0, z: -0.2 },
				// { type: "scale", x: 0.1, y: 0, z: 0 },
			],
		},
	],
	// materialGroups: [],
	// materials: [
	material55Default: [
		"mat_beige_left",
		"mat_beige_right",
		"mat_beige_top",
		"mat_glas",
		"mat_chrome2",
	],

	material55: [
		{
			id: "mat_beige_left",
			name: "gul",
			material: "mat_beige_left",
			target: ["#_item_1.001", "#_item_1_wall_right", "#_item_1_changeColor.004", "#_item_1"],
			action: "focusPoint_1",
			albedoColorHex: "#1D2E33",
		},

		{
			id: "mat_beige_right",
			name: "gul",
			material: "mat_beige_right",
			target: ["#_item_1.001", "#_item_1_wall_right", "#_item_1_changeColor.004", "#_item_1"],
			action: "focusPoint_default",
			albedoColorHex: "#1D2E33",
		},
		{
			id: "mat_beige_right2",
			name: "gul",
			material: "mat_beige_right",
			target: ["#_item_1.001", "#_item_1_wall_right", "#_item_1_changeColor.004", "#_item_1"],
			action: "focusPoint_default",
			albedoColorHex: "#000003",
		},

		{
			id: "mat_beige_top",
			name: "gul",
			material: "mat_beige_top",
			target: ["#_item_1.001", "#_item_1_wall_right", "#_item_1_changeColor.004", "#_item_1"],
			action: "focusPoint_1",
			albedoColorHex: "#1D2E33",
		},

		{
			id: "mat_glas",
			name: "glas",
			material: "mat_glas",
			target: ["#_item_1_glas"],
			action: "focusPoint_1",
			albedoColorHex: "#708990",
			settings: { roughness: 0.51, metallic: 0.12, alpha: 0.17 },
		},
		{
			id: "mat_chrome2",
			name: "chrome",
			material: "mat_chrome2",
			target: ["#_item_1_rails"],
			action: "focusPoint_1",
			albedoColorHex: "#373737",
			settings: { roughness: 0.43, metallic: 0.63, alpha: 1 },
		},
	],

	materialGroupsToShow: ["materialGroups2", "materialGroups3", "materialGroups4"],
	materialGroups: ["materialGroups2", "materialGroups3", "materialGroups4", "materialGroups5"],
	materialGroups2ActiveGroup: "gul",
	materialGroups2: [
		{
			name: "gul",
			material: "mat_beige_left",
			target: ["#_item_1.001", "#_item_1_wall_right", "#_item_1_changeColor.004", "#_item_1"],
			action: "focusPoint_1",
			albedoColorHex: "#1D2E33",
		},
	],
	materialGroups3ActiveGroup: "gul",
	materialGroups3: [
		{
			name: "gul",
			material: "mat_beige_right",
			target: ["#_item_1.001", "#_item_1_wall_right", "#_item_1_changeColor.004", "#_item_1"],
			action: "focusPoint_1",
			albedoColorHex: "#1D2E33",
		},
	],
	materialGroups4ActiveGroup: "gul",
	materialGroups4: [
		{
			name: "gul",
			material: "mat_beige_top",
			target: ["#_item_1.001", "#_item_1_wall_right", "#_item_1_changeColor.004", "#_item_1"],
			action: "focusPoint_1",
			albedoColorHex: "#1D2E33",
		},
	],
	materialGroups5ActiveGroup: "glas",
	materialGroups5: [
		{
			name: "glas",
			material: "mat_glas",
			target: ["#_item_1_glas"],
			action: "focusPoint_1",
			albedoColorHex: "#708990",
			settings: { roughness: 0.51, metallic: 0.12, alpha: 0.17 },
		},
		{
			name: "chrome",
			material: "mat_chrome2",
			target: ["#_item_1_rails"],
			action: "focusPoint_1",
			albedoColorHex: "#708990",
			settings: { roughness: 0.51, metallic: 0.12, alpha: 0.17 },
		},
	],
	translate: {
		focusPoint_edge: "Zooma in kant",
		focusPoint_handle: "Handtag",
		focusPoint_outside: "Utsida",
		focusPoint_inside: "Insida",
		white: "Vit",
		green: "Grön",
		red: "Röd",
		blue: "Blå",
		"01_op_deko_2_changeColor": "Spröjs utsida",
		"01_op_deko_2": "Spröjs insida vit",
		window_bars: "Spröjs",
	},
};
