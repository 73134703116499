export default {
	// import3DFile: { path: "silentia_screen2/", file: "silentia_screen2.babylon" },
	//import3DFile: { path: "basic_cube/", file: "basic_cube.babylon" },
	// import3DFile: { path: "enoc_w3/", file: "enoc_w3_1.babylon" },
	// import3DFile: { path: "silentia_screen/", file: "silentia_screen.babylon" },
	// import3DFile: { path: "enoc_w3/", file: "enoc_w3_1.babylon" },
	// import3DFile: { path: "window/", file: "window.babylon" },
	// import3DFile: { path: "", file: "axis.babylon" },
	// import3DFile: { path: "stol3", file: "test3.babylon" },
	sceneSettings: {
		babylonFile: { path: "/babylon/screen/", file: "screen.babylon" },
		babylonFile2: { path: "./media/screen/", file: "screen.babylon" },
		envPath: "/babylon/env/",
		envPath2: "./media/env/",
		envFiles: [
			"studio.env",
			"studio_small_08_2k.env",
			"photo_studio_01_2k.env",
			"flowerRoad_clamped.env",
			"frozen_lake_4k.env",
		],
		envActive: "photo_studio_01_2k.env",
		envdRotationY: 311,
		contrast: 0.84,
		exposure: 1.5,
		intesity: 0.9,
	},
	cameraSettings: {
		lowerAlphaLimit: -180 * 5, // ROTATE LIMIT
		upperAlphaLimit: 180 * 5, // ROTATE LIMIT
		lowerBetaLimit: 10, // TILT UP LIMIT
		upperBetaLimit: 95, // TILT DOWN LIMIT
		lowerRadiusLimit: 0.1, // ZOOM IN LIMIT
		upperRadiusLimit: 10, // ZOOM OUT LIMIT
		wheelDeltaPercentage: 0.00001, // ZOOM SPEED
		pinchDeltaPercentage: 0.00001, // ZOOM SPEED
		panningSensibility: 0.001, // PANNING SPEED 0 : off
		panningDistanceLimit: 0.001,
		minZ: 0.1, // VIEW 3D NEARBY
		maxZ: 15, // VIEW 3D FAR DISTANCE
		fov: 0.6, // FIELD OF VIEW
		useAutoRotationBehavior: false, // AUTO ROTATE CAMERA
		viewport: [0.0, 0.0, 1.0, 1.0], // CENTER VIEW
	},

	uiSettings: {
		// guiNavButtons: true,
		// materials: true,
		// optionsSlider: true,
		// optionsButtons: true,
		// settingsButton: true,
		// materialsButton: true,
		// materialsSlider: true,
		// animationButtons: false,
		// animationSlider: false,
		// animationSwitch: true,
		// combActionButton: true,
		//////////////////
		guiNavButtons: false,
		materials: false,
		optionsSlider: true,
		optionsButtons: false,
		settingsButton: false,
		materialsButton: false,
		materialsSlider: false,
		animationButtons: false,
		animationSlider: false,
		animationSwitch: false,
		combActionButton: false,
	},

	header: "Ändra storlek",

	active: {
		optionalMeshes: "",
		focusPoints: "focusPoint_default",
		materials: "white",
	},

	lights: [
		// {
		// 	name: "A",
		// 	type: "PointLight", // DirectionalLight, HemisphericLight
		// 	intensity: 3.2,
		// 	position: { x: "0.61", y: "0.28", z: "-0.10" },
		// 	diffuse: { r: 1, g: 1, b: 1 },
		// 	specular: { r: 1, g: 1, b: 1 },
		// },
		{
			name: "A",
			type: "PointLight",
			intensity: 1.13,
			position: { x: 1.29, y: 0.9, z: -3.06 },
			diffuse: { r: 1, g: 1, b: 1 },
			specular: { r: 1, g: 1, b: 1 },
		},
		{
			name: "B",
			type: "PointLight",
			intensity: 0,
			position: { x: 2.06, y: 1.62, z: 2.28 },
			diffuse: { r: 1, g: 1, b: 1 },
			specular: { r: 1, g: 1, b: 1 },
		},
		// {
		// 	name: "C",
		// 	type: "HemisphericLight",
		// 	intensity: 8,
		// 	position: { x: "1.29", y: "0.90", z: "-3.06" },
		// 	diffuse: { r: 1, g: 1, b: 1 },
		// 	specular: { r: 1, g: 1, b: 1 },
		// },
	],
	menu55: [
		{ id: "root", type: "group", childrenId: ["meshOptions", "materialScreen"] },
		{ id: "meshOptions", type: "group", childrenId: ["mesh"] },
		
		{ id: "textSize", type: "text", text: "Ändra storlek" },
		{
			id: "mesh",
			type: "mesh",
			ui: "ButtonColorControl",
			active: "_item_4",
			text: "Ändra storlek",
			childrenId: ["_item_1", "_item_2", "_item_3", "_item_4", "_item_5", "_item_6"],
		},
		// { id: "root", type: "group", childrenId: ["mesh", "flex materials"] },
		// { id: "flex materials", type: "group", childrenId: ["materials21"] },
		// {
		// 	id: "materials21",
		// 	type: "material",
		// 	ui: "ButtonColorControl",
		// 	active: "screen_gul",
		// 	text: "Färger",
		// 	childrenId: ["screen_gul", "screen_gul1"],
		// },
	],

	meshes55: [
		{
			id: "_item_1",
			text: "1m",
			name: "#_item_1",
			target: ["#_item_1"],
			active: true,
		},
		{
			id: "_item_2",
			text: "1.25m",
			name: "#_item_2",
			target: ["#_item_2"],
			active: true,
		},
		{
			id: "_item_3",
			text: "1.5m",
			name: "#_item_3",
			target: ["#_item_3"],
			active: true,
		},
		{
			id: "_item_4",
			text: "1.75m",
			name: "#_item_4",
			target: ["#_item_4"],
			active: true,
		},
		{
			id: "_item_5",
			text: "2.0m",
			name: "#_item_5",
			target: ["#_item_5"],
			active: true,
		},
		{
			id: "_item_6",
			text: "2.25m",
			name: "#_item_6",
			target: ["#_item_6"],
			active: true,
		},
	],

	meshGroupsActiveGroup: "#_item_3",
	meshGroups: [
		{
			name: "#_item_1",
			target: ["#_item_1"],
			active: true,
		},
		{
			name: "#_item_2",
			target: ["#_item_2"],
			active: true,
		},
		{
			name: "#_item_3",
			target: ["#_item_3"],
			active: true,
		},
		{
			name: "#_item_4",
			target: ["#_item_4"],
			active: true,
		},
		{
			name: "#_item_5",
			target: ["#_item_5"],
			active: true,
		},
		{
			name: "#_item_6",
			target: ["#_item_6"],
			active: true,
		},
	],
	isReady: true,
	startAction: "start",
	actions: [
		{
			name: "start",
			actions: [
				{
					focusPoint: "focusPoint_default",
					animation: [],
				},
			],
		},
		{
			name: "start-back",
			actions: [
				{
					focusPoint: "focusPoint_default",
					animation: [],
				},
			],
		},
		{
			name: "focusPoint_wheel",
			actions: [
				{
					focusPoint: "focusPoint_wheel",
					animation: [],
				},
			],
		},
		{
			name: "focusPoint_wheel-back",
			actions: [
				{
					focusPoint: "focusPoint_default",
					animation: [],
				},
			],
		},
		{
			name: "focusPoint_wheel2",
			actions: [
				{
					focusPoint: "focusPoint_wheel2",
					animation: [],
				},
			],
		},
		{
			name: "focusPoint_wheel2-back",
			actions: [
				{
					focusPoint: "focusPoint_default",
					animation: [],
				},
			],
		},
		{
			name: "focusPoint_default",
			actions: [
				{
					focusPoint: "focusPoint_default",
					animation: [],
				},
			],
		},
		{
			name: "focusPoint_default-back",
			actions: [
				{
					focusPoint: "focusPoint_default",
					animation: [],
				},
			],
		},
	],
	focusPointsVisible: ["focusPoint_wheel", "focusPoint_handle", "focusPoint_mount"],
	focusPoints: [
		{
			name: "focusPoint_default",
			target: "focusPoint_default",
			return: "focusPoint_default",
			action: "start",
			lable: "fD",
			position: { x: 0.77, y: 0.94, z: 0.27 },
			scaling: 0.44,
			alpha: 56,
			beta: 84,
			radius: 7.1,
			mesh: "focusPoint_default",
		},
		// {
		// 	name: "focusPoint_wheel2",
		// 	target: "focusPoint_wheel2",
		// 	return: "focusPoint_default",
		// 	action: "start",
		// 	lable: "fD",
		// 	position: { x: 0.25, y: 0.78, z: -0.49 },
		// 	scaling: 1,
		// 	alpha: 44,
		// 	beta: 77,
		// 	radius: 7.1,
		// 	mesh: "focusPoint_wheel",
		// },
		{
			name: "focusPoint_wheel2",
			target: "focusPoint_wheel2",
			return: "focusPoint_default",
			action: "start",
			lable: "fD",
			position: { x: 1.67, y: 0.78, z: -1.45 },
			scaling: 1.68,
			alpha: 56,
			beta: 84,
			radius: 7.1,
			mesh: "focusPoint_wheel2",
		},
	],

	animations: [
		{
			name: "animation_item_1.020",
			target: "animation_item_1.020",
			animation: [
				{ type: "rotation", x: 0, y: -90, z: 0 },
				{ type: "position", x: 0, y: 0, z: 0 },
				// { type: "scale", x: 0.1, y: 0, z: 0 },
			],
		},
	],
	material55Default: ["screen_gul"],

	material55: [
		{
			id: "screen_gul",
			name: "gul",
			material: "screen_color",
			target: ["#_item_2_end__changeColor.001"],
			action: "focusPoint_wheel2",
			albedoColorHex: "#DDAB2B",
		},
		{
			id: "screen_gul1",
			name: "gul1",
			material: "screen_color",
			target: ["#_item_2_end__changeColor.001"],
			action: "focusPoint_default",
			albedoColorHex: "#0000",
		},
	],
	materialGroupsToShow: ["materialGroups2"],
	materialGroups: ["materialGroups2"],
	materialGroups2ActiveGroup: "gul",
	materialGroups2: [
		{
			name: "gul",
			material: "screen_color",
			target: ["#_item_1.002"],
			action: "focusPoint_1",
			albedoColorHex: "#DDAB2B",
		},
	],

	translate: {
		focusPoint_edge: "Zooma in kant",
		focusPoint_handle: "Handtag",
		focusPoint_outside: "Utsida",
		focusPoint_inside: "Insida",
		white: "Vit",
		green: "Grön",
		red: "Röd",
		blue: "Blå",
		"01_op_deko_2_changeColor": "Spröjs utsida",
		"01_op_deko_2": "Spröjs insida vit",
		window_bars: "Spröjs",
		"#_item_1": "1m",
		"#_item_2": "1.25m",
		"#_item_3": "1.5m",
		"#_item_4": "1.75m",
		"#_item_5": "2.0m",
		"#_item_6": "2.25m",
	},
};
