import * as BABYLON from "@babylonjs/core";

export function addDraggableMesh({ item, mainScene }) {

  let position = new BABYLON.Vector3(item.position.x, item.position.y, item.position.z);
  let name = item.name;
  let itemMesh = BABYLON.MeshBuilder.CreateSphere(name, { diameter: 1, diameterX: 1 }, mainScene);

  itemMesh.scaling = new BABYLON.Vector3(item.scaling, item.scaling, item.scaling);
  itemMesh.position = position;
  var boxMaterial = new BABYLON.StandardMaterial("draggable_mat", mainScene);
  boxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
  boxMaterial.alpha = 0.6; // value of 0.3 is applied fro transparency
  itemMesh.material = boxMaterial;
  itemMesh.isVisible = false;


  if (!(item.parent === "" || undefined)) {
    const parent = mainScene.getMeshByName(item.parent);
    itemMesh.parent = parent;
  }

  addPointerDragBehavior(itemMesh, mainScene);
}

function addPointerDragBehavior(item, mainScene) {
  var pointerDragBehavior = new BABYLON.PointerDragBehavior({
    dragPlaneNormal: new BABYLON.Vector3(0, 1, 0),
  });
  pointerDragBehavior.useObjectOrienationForDragging = false;

  pointerDragBehavior.onDragStartObservable.add((event) => {
    // grab top half to move on y axis, bottom half to move in xz plane.
    if (event.dragPlanePoint.z > item._position.z) {
      pointerDragBehavior._options = { dragAxis: new BABYLON.Vector3(0, 1, 0) };
    }
  });

  pointerDragBehavior.onDragEndObservable.add((event) => {
    pointerDragBehavior._options = { dragPlaneNormal: new BABYLON.Vector3(0, 1, 0) };
  });

  item.addBehavior(pointerDragBehavior);
}
