export const configurator0 = {
  sceneSettings: {
    babylonFile: { path: "/babylon/template/", file: "template.babylon" },
    envPath: "/babylon/env/",
    envFiles: [
      "studio.env",
      "studio_small_08_2k.env",
      "photo_studio_01_2k.env",
      "flowerRoad_clamped.env",
      "frozen_lake_4k.env",
    ],
    envActive: "studio_small_08_2k.env",
    envdRotationY: 210,
    contrast: 3,
    // contrast: 1.83,
    exposure: 3,
    intesity: 1.05,
  },

  uiSettings: {
    // guiNavButtons: true,
    // materials: true,
    // optionsSlider: true,
    // optionsButtons: true,
    // settingsButton: true,
    // materialsButton: true,
    // materialsSlider: true,
    // animationButtons: false,
    // animationSlider: false,
    // animationSwitch: true,
    // combActionButton: true,
    //////////////////
    guiNavButtons: true,
    materials: false,
    optionsSlider: false,
    optionsButtons: false,  
    settingsButton: false,
    materialsButton: true,
    materialsSlider: false,
    animationButtons: false,
    animationSlider: false,
    animationSwitch: false,
    combActionButton: false,
  },
  cameraSettings: {},

  active: {
    optionalMeshes: "",
    focusPoints: "focusPoint_default",
    materials: "white",
  },
  meshGroupsActiveGroup: "#_item_1",
  meshGroups: [
    {
      name: "#_item_1",
      target: ["#_item_1"],
      active: true,
    },
    {
      name: "#_item_2",
      target: ["#_item_2"],
      active: true,
    },
  ],
  isReady: true,
  items: [{ name: "bag", target: ["#_item_1", "#_item_2"], active: true }],
  lights: [
    {
      name: "A",
      type: "PointLight",
      intensity: 3.64,
      position: { x: "0.74", y: "1.22", z: "-0.12" },
      diffuse: { r: 1, g: 1, b: 1 },
      specular: { r: 1, g: 1, b: 1 },
    },
    {
      name: "B",
      type: "PointLight",
      intensity: 3.64,
      position: { x: "-0.49", y: "1.06", z: "0.81" },
      diffuse: { r: 1, g: 1, b: 1 },
      specular: { r: 1, g: 1, b: 1 },
    },
    {
      name: "C",
      type: "PointLight",
      intensity: 0.5,
      position: { x: -0.19, y: 0.5, z: -0.58 },
      diffuse: { r: 1, g: 1, b: 1 },
      specular: { r: 1, g: 1, b: 1 },
    },
    // {
    // 	name: "B",
    // 	type: "DirectionalLight",
    // 	intensity: 3.2,
    // 	position: { x: "0.61", y: "0.17", z: "-0.10" },
    // 	diffuse: { r: 1, g: 1, b: 1 },
    // 	specular: { r: 1, g: 1, b: 1 },
    // },
    // { name: "lightA", intensity: 1, position: { _isDirty: false, _x: 0, _y: 0.5, _z: 0 } },
    // {
    //   name: "lightB",
    // 	type: "HemisphericLight",
    //   intensity: 1,
    // 	position: { x: 0.5, y: 0, z: 0 },
    // 	target: { x: 0, y: 0, z: 0 },
    // },
    // {
    //   name: "lightC",
    // 	type: "DirectionalLight",
    //   intensity: 2,
    // 	position: { x: 0, y: -1, z: 0 },
    // 	target: { x: 0, y: 0, z: 0 },
    // },
  ],
  startAction: "start",
  actions: [
    {
      name: "start",
      actions: [
        {
          focusPoint: "focusPoint_default",
          animation: [],
        },
      ],
    },
    {
      name: "start-back",
      actions: [
        {
          focusPoint: "focusPoint_default",
          animation: [],
        },
      ],
    },

    {
      name: "focusPoint_1",
      actions: [
        {
          focusPoint: "focusPoint_1",
          animation: [],
        },
      ],
    },
    {
      name: "focusPoint_1-back",
      actions: [
        {
          focusPoint: "focusPoint_default",
          animation: [],
        },
      ],
    },
    {
      name: "focusPoint_2",
      actions: [
        {
          focusPoint: "focusPoint_2",
          animation: [],
        },
      ],
    },
    {
      name: "focusPoint_2-back",
      actions: [
        {
          focusPoint: "focusPoint_default",
          animation: [],
        },
      ],
    },
  ],
  focusPoints: [
    {
      name: "focusPoint_1",
      target: "focusPoint_1",
      action: "focusPoint_1",
      return: "focusPoint_default",
      lable: "f1",
      position: { x: -0.25, y: 0.8, z: 0.24 },
      scaling: 0.11,
      alpha: -252,
      beta: 65,
      radius: 0.53,
      mesh: "focusPoint_1",
    },
    {
      name: "focusPoint_2",
      target: "focusPoint_2",
      return: "focusPoint_default",
      action: "focusPoint_2",
      lable: "f2",
      position: { x: -0.01, y: 0.63, z: -0.27 },
      scaling: 0.09,
      alpha: -58,
      beta: 84,
      radius: 0.71,
      mesh: "focusPoint_2",
    },

    {
      name: "focusPoint_default",
      target: "focusPoint_default",
      return: "focusPoint_default",
      action: "start",
      lable: "fD",
      position: { x: -0.01, y: 0.61, z: 0 },
      scaling: 1,
      alpha: -124,
      beta: 77,
      radius: 2.03,
      mesh: "focusPoint_default",
    },
  ],

  animations: [
    {
      name: "animation_item_1",
      target: "animation_item_1",
      animation: [
        { type: "rotation", x: 0, y: 55, z: 0 },
        { type: "position", x: 0, y: 0, z: 0 },
      ],
    },
    {
      name: "#_item_1_door_look",
      target: "#_item_1_door_look",
      // target: ["#_item_1_door_look"],
      animation: [
        { type: "rotation", x: -90, y: 0, z: 0 },
        { type: "position", x: 0, y: 0, z: 0 },
        // { type: "scale", x: 0.1, y: 0, z: 0 },
      ],
    },
    {
      name: "#_item_1_changeColor.003",
      target: "#_item_1_changeColor.003",
      animation: [
        { type: "rotation", x: 0, y: 0, z: 0 },
        { type: "position", x: 0, y: 0, z: 0.2 },
        // { type: "scale", x: 0.1, y: 0, z: 0 },
      ],
    },
    {
      name: "railMove1",
      target: "#_item_1_changeColor.003",
      animation: [
        { type: "rotation", x: 0, y: 0, z: 0 },
        { type: "position", x: 0, y: -1, z: 0 },
        // { type: "scale", x: 0.1, y: 0, z: 0 },
      ],
    },
    {
      name: "railMove2",
      target: "#_item_1_changeColor.004",
      animation: [
        { type: "rotation", x: 0, y: 0, z: 0 },
        { type: "position", x: 0, y: -1, z: 0 },
        // { type: "scale", x: 0.1, y: 0, z: 0 },
      ],
    },
    {
      name: "railMove3",
      target: "animation_item_1",
      animation: [
        { type: "rotation", x: 0, y: 0, z: 0 },
        { type: "position", x: 0, y: -1, z: 0 },
        // { type: "scale", x: 0.1, y: 0, z: 0 },
      ],
    },
    {
      name: "#_item_1_changeColor.004",
      target: "#_item_1_changeColor.004",
      animation: [
        { type: "rotation", x: 0, y: 0, z: 0 },
        { type: "position", x: 0, y: 0, z: -0.2 },
        // { type: "scale", x: 0.1, y: 0, z: 0 },
      ],
    },
  ],
  // {
  //   name: "group1",
  //   target: ["#_item_1.001"],
  //   materials: ["#_item_1.001", "material_1.001_baked", "material_1.002_baked"],
  //   action: "start",
  // },
  materialGroups: ["materials", "materials2"],
  materials: [
    {
      name: "material_0",
      material: "material_1_baked",
      target: ["#_item_1.001"],
      action: "start",
    },
    {
      name: "material_1",
      material: "material_1.001_baked",
      target: ["#_item_1.001"],
      action: "start",
    },
    {
      name: "material_2",
      material: "material_1.002_baked",
      target: ["#_item_1.001"],
      action: "start",
    },
    {
      name: "material_3",
      material: "material_1.005_baked",
      target: ["#_item_1.001"],
      action: "start",
    },
    {
      name: "material_4",
      material: "material_1.006_baked",
      target: ["#_item_1.001"],
      action: "start",
    },
    {
      name: "material_5",
      material: "material_1.007_baked",
      target: ["#_item_1.001"],
      action: "start",
    },
  ],
  materials2: [
    {
      name: "material_0",
      material: "material_1_baked",
      target: ["#_item_1.002"],
      action: "start",
    },
    {
      name: "material_1",
      material: "material_1.001_baked",
      target: ["#_item_1.002"],
      action: "start",
    },
    {
      name: "material_2",
      material: "material_1.002_baked",
      target: ["#_item_1.002"],
      action: "start",
    },
    {
      name: "material_3",
      material: "material_1.005_baked",
      target: ["#_item_1.002"],
      action: "start",
    },
    {
      name: "material_4",
      material: "material_1.006_baked",
      target: ["#_item_1.002"],
      action: "start",
    },
    {
      name: "material_5",
      material: "material_1.007_baked",
      target: ["#_item_1.002"],
      action: "start",
    },
  ],
  translate: {
    focusPoint_edge: "Zooma in kant",
    focusPoint_handle: "Handtag",
    focusPoint_outside: "Utsida",
    focusPoint_inside: "Insida",
    white: "Vit",
    green: "Grön",
    red: "Röd",
    blue: "Blå",
    "01_op_deko_2_changeColor": "Spröjs utsida",
    "01_op_deko_2": "Spröjs insida vit",
    window_bars: "Spröjs",
  },
};
