import React, { useState, useEffect, useRef } from "react";
import {
	SliderControl,
	ButtonControl,
	ButtonColorControl,
	SwitchControl,
	getLang,
} from "./Controls";
import { sceneActionHandler } from "../func/sceneActionHandler";
import { updateMesh, updateMaterial } from "../func/updateGraphic";

export function AnimateOptions({ mainScene, confSetup, type }) {
	const { animationSwitch, animationButton } = confSetup.uiSettings;

	const animateMeshHandler = (animation) => {
		// let mesh = mainScene.meshes.find((mesh) => mesh.name === "focusPoint_default");
		// animateCamera(mesh, mainScene, confSetup); // MOVE CAMERA
	};

	return (
		<div>
			{animationButton && (
				<ButtonControl type={type} actionHandler={animateMeshHandler} confSetup={confSetup} />
			)}
			{animationSwitch && (
				<SwitchControl type={type} actionHandler={animateMeshHandler} confSetup={confSetup} />
			)}
		</div>
	);
}

export function Menu55({ confSetup, mainScene }) {
	const root = confSetup.menu55.find((item) => item.id === "root");
	return <Group55 mainScene={mainScene} parent={root} confSetup={confSetup} />;
}

export function Group55({ confSetup, mainScene, parent }) {
	const children = confSetup.menu55.filter((i) => parent.childrenId.includes(i.id));

	return (
		<div className={`${parent.flex ? "flex " : ""} ${parent.id}`}>
			{children.map((child, index) => {
				if (child.type === "group")
					return <Group55 key={index} mainScene={mainScene} parent={child} confSetup={confSetup} />;
				if (child.type === "text")
					return <Header55 key={index} mainScene={mainScene} item={child} confSetup={confSetup} />;
				if (child.type === "material")
					return (
						<Material55 key={index} item={child} mainScene={mainScene} confSetup={confSetup} />
					);
				if (child.type === "mesh")
					return (
						<MeshOptions55 key={index} item={child} mainScene={mainScene} confSetup={confSetup} />
					);
			})}
		</div>
	);
}

function Header55({ confSetup, item }) {
	return <label className={item.id}>{item.text}</label>;
}

function Material55({ mainScene, confSetup, item }) {
	const [state, setState] = useState(item.active);

	useEffect(() => {
		updateMaterial({ active: state, mainScene, confSetup });
	}, []);

	const options = confSetup.material55.filter((i) => item.childrenId.includes(i.id));

	const actionHandler = async (item) => {
		updateMaterial({ type: "material55", active: item.id, mainScene, confSetup });
		const action = confSetup.actions.find((item) => item.id === item.action);
		await sceneActionHandler({ action, actionName: item.action, mainScene, confSetup });
	};

	return (
		<div className='colour-selection'>
			<span>{item.text}</span>
			{options.map((item, index) => (
				<button
					className={`${item.id === state ? "active" : ""} ${item.name}`}
					key={item.id}
					onClick={() => {
						actionHandler(item);
						setState(item.id);
					}}></button>
			))}
		</div>
	);
}

export function MeshOptions55({ mainScene, confSetup, item }) {
	const showHideMeshHandler = (item2) => {
		updateMesh(item2.name, mainScene, confSetup);
	};

	return <SliderControl55 item={item} actionHandler={showHideMeshHandler} confSetup={confSetup} />;
}

export function SliderControl55({ actionHandler, confSetup, item }) {
	const ref = useRef();
	const options = confSetup.meshes55.filter((i) => item.childrenId.includes(i.id));

	const index = options.findIndex((i) => i.id === item.active);
	const [value, setValue] = useState(index);

	useEffect(() => {
		style_slider55();
		return () => {
			style_slider55();
		};
	});

	return (
		<div className='length-content'>
			<label>{item.text}</label>
			<div ref={ref} className='length-selection'>
				<input
					type='range'
					list='lengths'
					value={value}
					max={options.length - 1}
					min={0}
					step={1}
					onChange={(e) => {
						actionHandler(options[e.target.value]);
						setValue(e.target.value);
					}}
				/>
				<div id='lengths'>
					{options.map((option, index) => (
						<span key={index} id={index} value={index}>
							{option.text}
						</span>
					))}
				</div>
				<output />
			</div>
		</div>
	);
}

// function style_slider_update55() {
// 	document.querySelectorAll(".length-selection").forEach(function(ctrl) {
// 		var el = ctrl.querySelector("input");
// 		el.oninput();
// 	});
// }

function style_slider55() {
	/* Range slider Length */
	document.querySelectorAll(".length-selection").forEach(function(ctrl) {
		var el = ctrl.querySelector("input");
		var dl = ctrl.querySelector("#lengths");
		var output = ctrl.querySelector("output");

		el.oninput = function() {
			// colorize step options
			ctrl.querySelectorAll("span").forEach(function(opt) {
				if (opt.id <= el.valueAsNumber) opt.style.backgroundColor = "#E4CC7A";
				else opt.style.backgroundColor = "#E3E3E3";
			});
			var valPercent =
				(el.valueAsNumber - parseInt(el.min)) / (parseInt(el.max) - parseInt(el.min));
			var style =
				"background-image: -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(" +
				valPercent +
				", #E4CC7A), color-stop(" +
				valPercent +
				", #E3E3E3));";
			dl.style = style;
			// Popup
			if ((" " + ctrl.className + " ").indexOf(" " + "length-selection" + " ") > -1) {
				var selectedOpt = ctrl.querySelector('span[value="' + el.value + '"]');
				//output.innerText = selectedOpt.text;
				output.innerText = selectedOpt.innerText;
				output.style.left = "50%";
				output.style.left =
					selectedOpt.offsetLeft + selectedOpt.offsetWidth / 2 - output.offsetWidth / 2 + "px";

				el.style.left = output.style.left;
			}
		};
		el.oninput();
	});
	window.onresize = function() {
		document.querySelectorAll(".length-selection").forEach(function(ctrl) {
			var el = ctrl.querySelector("input");
			el.oninput();
		});
	};
}

export function MaterialOptions({ mainScene, confSetup, type }) {
	const { materialsButton, materialsSlider } = confSetup.uiSettings;

	const changeMaterialHandler = async (item, type) => {
		updateMaterial({ type, active: item.name, mainScene, confSetup });
		const action = confSetup.actions.find((item) => item.name === item.action);

		await sceneActionHandler({ action, actionName: item.action, mainScene, confSetup });
	};

	const material55 = () => {
		return confSetup.materialGroupsToShow.map((group) => (
			<ButtonColorControl
				key={group}
				type={group}
				actionHandler={changeMaterialHandler}
				confSetup={confSetup}
				active={confSetup[group + "ActiveGroup"]}
			/>
		));
	};

	return (
		<div>
			<div className='flex'>{materialsButton && material55()}</div>
			{/* {materialsSlider && (
				<SliderControl type={type} actionHandler={changeMaterialHandler} confSetup={confSetup} />
			)} */}
		</div>
	);
}

export function MeshOptions({ mainScene, confSetup, type }) {
	const { optionsSlider, optionsButtons } = confSetup.uiSettings;

	const showHideMeshHandler = (item2) => {
		updateMesh(item2.name, mainScene, confSetup);
	};

	return (
		<div>
			{optionsButtons && (
				<ButtonControl type={type} actionHandler={showHideMeshHandler} confSetup={confSetup} />
			)}
			{optionsSlider && (
				<SliderControl type={type} actionHandler={showHideMeshHandler} confSetup={confSetup} />
			)}
		</div>
	);
}
