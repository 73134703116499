import { animateCameraPromise } from "./animateCameraPromise";
import { animateMeshPromise } from "./animateMeshPromise";

export async function sceneActionHandler({ actionName, mainScene, confSetup, action }) {


	return new Promise(async function (resolve, reject) {

		action = confSetup.actions.find((item) => item.name === actionName);

		if (action === undefined) {
			console.log("ACTION IS MISSING!!! " + action.name);
			return;
		}

		if (confSetup.isReady === false) return;

		confSetup.isReady = false;
		for (let i = 0; i < action.actions.length; i++) {
			const item = action.actions[i];
			await animationGroup(item, mainScene, confSetup);
		}
		confSetup.isReady = true;

		resolve();
	});
}

async function animationGroup(item, mainScene, confSetup) {
	if (!(item.focusPoint === undefined || item.focusPoint === "")) {
		await animateCameraPromise({ mainScene, confSetup, focusPointName: item.focusPoint});
	}

	return new Promise(function (resolve, reject) {
		const animtaionArrayLength = item.animation.length;
		let animationCount = 0;

		if (animtaionArrayLength === 0 || animtaionArrayLength === undefined) {
			resolve("done!");
		}
		function animationArraySuccess(i) {
			animationCount++;
			if (animationCount === animtaionArrayLength) {
				resolve("done!");
			}
		}
		for (let i2 = 0; i2 < item.animation.length; i2++) {
			let animation = confSetup.animations.find((x) => x.name === item.animation[i2]);

			if (animation === undefined) console.log(item.animation[i2] + " - MISSING ANIMATION!!!")
			animateMeshPromise({ mainScene, animation, animationArraySuccess });
		}
	});
}


