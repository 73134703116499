export default {
	sceneSettings: {
		babylonFile: { path: "/babylon/bag/", file: "bag.babylon?v=3" },
		babylonFile2: { path: "./media/bag/", file: "bag.babylon" },
		envPath: "/babylon/env/",
		envFiles: [
			"studio.env",
			"studio_small_08_2k.env",
			"photo_studio_01_2k.env",
			"flowerRoad_clamped.env",
			"frozen_lake_4k.env",
		],
		envActive: "studio.env",
		envdRotationY: 0,
		contrast: 1.33,
		exposure: 0.34,
		intesity: 3,
	},
	cameraSettings: {
		lowerAlphaLimit: -180 * 5, // ROTATE LIMIT
		upperAlphaLimit: 180 * 5, // ROTATE LIMIT
		lowerBetaLimit: 10, // TILT UP LIMIT
		upperBetaLimit: 95, // TILT DOWN LIMIT
		lowerRadiusLimit: 0.1, // ZOOM IN LIMIT
		upperRadiusLimit: 10, // ZOOM OUT LIMIT
		wheelDeltaPercentage: 0.00001, // ZOOM SPEED
		pinchDeltaPercentage: 0.00001, // ZOOM SPEED
		panningSensibility: 0.001, // PANNING SPEED 0 : off
		panningDistanceLimit: 0.001,
		minZ: 0.01, // VIEW 3D NEARBY
		maxZ: 15, // VIEW 3D FAR DISTANCE
		fov: 0.6, // FIELD OF VIEW
		useAutoRotationBehavior: false, // AUTO ROTATE CAMERA
		viewport: [0.0, 0.0, 1.0, 1.0], // CENTER VIEW
	},
	optionControls: {
		animation: 0,
		options: 0,
		material: 0,
		options: 0,
	},
	uiSettings: {
		// guiNavButtons: true,
		// materials: true,
		// optionsSlider: true,
		// optionsButtons: true,
		// settingsButton: true,
		// materialsButton: true,
		// materialsSlider: true,
		// animationButtons: false,
		// animationSlider: false,
		// animationSwitch: true,
		// combActionButton: true,
		//////////////////
		guiNavButtons: false,
		materials: false,
		optionsSlider: false,
		optionsButtons: false,
		settingsButton: false,
		materialsButton: true,
		materialsSlider: false,
		animationButtons: false,
		animationSlider: false,
		animationSwitch: false,
		combActionButton: false,
	},
	header: "Välj färg",

	active: {
		optionalMeshes: "",
		focusPoints: "focusPoint_default",
		materials: "white",
	},
	meshGroupsActiveGroup: "#_item_1",
	meshGroups: [
		{
			name: "#_item_1",
			target: ["#_item_1"],
			active: true,
		},
		{
			name: "#_item_2",
			target: ["#_item_2"],
			active: true,
		},
	],

	// isReady: true,

	items: [{ name: "bag", target: ["#_item_1", "#_item_2"], active: true }],
	lights: [
		{
			name: "A",
			type: "PointLight",
			intensity: 3.1,
			position: { x: -0.3, y: 0.29, z: 0.2 },
			diffuse: { r: 1, g: 1, b: 1 },
			specular: { r: 1, g: 1, b: 1 },
		},
		{
			name: "B",
			type: "PointLight",
			intensity: 0.15,
			position: { x: 0.17, y: 0.2, z: 0.06 },
			diffuse: { r: 1, g: 1, b: 1 },
			specular: { r: 1, g: 1, b: 1 },
		},
		{
			name: "C",
			type: "PointLight",
			intensity: 0.32,
			position: { x: -0.08, y: 0.16, z: -0.23 },
			diffuse: { r: 1, g: 1, b: 1 },
			specular: { r: 1, g: 1, b: 1 },
		},
		{
			name: "D",
			type: "PointLight",
			intensity: 0.15,
			position: { x: 0.04, y: 0.19, z: -0.12 },
			diffuse: { r: 1, g: 1, b: 1 },
			specular: { r: 1, g: 1, b: 1 },
		},

		// {
		// 	name: "B",
		// 	type: "DirectionalLight",
		// 	intensity: 3.2,
		// 	position: { x: "0.61", y: "0.17", z: "-0.10" },
		// 	diffuse: { r: 1, g: 1, b: 1 },
		// 	specular: { r: 1, g: 1, b: 1 },
		// },
		// { name: "lightA", intensity: 1, position: { _isDirty: false, _x: 0, _y: 0.5, _z: 0 } },
		// {
		//   name: "lightB",
		// 	type: "HemisphericLight",
		//   intensity: 1,
		// 	position: { x: 0.5, y: 0, z: 0 },
		// 	target: { x: 0, y: 0, z: 0 },
		// },
		// {
		//   name: "lightC",
		// 	type: "DirectionalLight",
		//   intensity: 2,
		// 	position: { x: 0, y: -1, z: 0 },
		// 	target: { x: 0, y: 0, z: 0 },
		// },
	],
	startAction: "start",
	actions: [
		{
			name: "start",
			actions: [
				{
					focusPoint: "focusPoint_default",
					animation: [],
				},
			],
		},
		{
			name: "start-back",
			actions: [
				{
					focusPoint: "focusPoint_default",
					animation: [],
				},
			],
		},

		{
			name: "focusPoint_1",
			actions: [
				{
					focusPoint: "focusPoint_1",
					animation: [],
				},
			],
		},
		{
			name: "focusPoint_1-back",
			actions: [
				{
					focusPoint: "focusPoint_default",
					animation: [],
				},
			],
		},
		{
			name: "focusPoint_2",
			actions: [
				{
					focusPoint: "focusPoint_2",
					animation: [],
				},
			],
		},
		{
			name: "focusPoint_2-back",
			actions: [
				{
					focusPoint: "focusPoint_default",
					animation: [],
				},
			],
		},
	],
	focusPointsVisible: [],
	focusPoints: [
		{
			name: "focusPoint_2",
			target: "focusPoint_2",
			return: "focusPoint_default",
			action: "focusPoint_2",
			lable: "f2",
			position: { x: 0, y: 0.1, z: 0 },
			scaling: 0.2,
			alpha: -122,
			beta: 77,
			radius: 0.4,
			mesh: "focusPoint_2",
		},
		// {
		// 	name: "focusPoint_2",
		// 	target: "focusPoint_2",
		// 	return: "focusPoint_default",
		// 	action: "focusPoint_2",
		// 	lable: "f2",
		// 	position: { x: -0.05, y: 0.1, z: -0.07 },
		// 	scaling: 0.08,
		// 	alpha: -126,
		// 	beta: 91,
		// 	radius: 0.3,
		// 	mesh: "focusPoint_2",
		// },

		{
			name: "focusPoint_default",
			target: "focusPoint_default",
			return: "focusPoint_default",
			action: "start",
			lable: "fD",
			position: { x: 0, y: 0.08, z: 0 },
			scaling: 0.21,
			alpha: -64,
			beta: 77,
			radius: 0.62,
			mesh: "focusPoint_default",
		},
	],

	animations: [
		{
			name: "animation_item_1",
			target: "animation_item_1",
			animation: [
				{ type: "rotation", x: 0, y: 55, z: 0 },
				{ type: "position", x: 0, y: 0, z: 0 },
			],
		},
	],

	menu55: [
		{ id: "root", type: "group", childrenId: ["materials"] },
		{
			id: "materials",
			type: "group",
			flex: true,
			childrenId: ["bag-text", "bagColor1", "bagColor2"],
		},
		{ id: "bag-text", type: "text", text: "Välj färg" },
		{
			id: "bagColor1",
			type: "material",
			ui: "ButtonColorControl",
			active: "_bag1-green",
			text: "Ryggsäck",
			childrenId: ["_bag1-blue", "_bag1-brown", "_bag1-green"],
		},
		{
			id: "bagColor2",
			type: "material",
			ui: "ButtonColorControl",
			active: "_bag2-blue",
			text: "Fickor",
			childrenId: ["_bag2-yellow", "_bag2-blue", "_bag2-green"],
		},
	],
	// material55Default: ["_bag1-green", "_bag2-yellow", "_details_1-brown"],
	material55Default: ["_details_1-brown"],

	material55: [
		{
			id: "_bag1-blue",
			name: "blue",
			material: "_bag1",
			albedoColorHex: "#6B888C",
			target: ["#_item_1_bag_1"],
			action: "start",
		},
		{
			id: "_bag1-brown",
			name: "brown",
			material: "_bag1",
			albedoColorHex: "#794A2E",
			target: ["#_item_1_bag_1"],
			action: "start",
		},
		{
			id: "_bag1-green",
			name: "green",
			material: "_bag1",
			albedoColorHex: "#6F8C5A",
			target: ["#_item_1_bag_1"],
			action: "start",
		},
		{
			id: "_bag2-yellow",
			name: "yellow",
			material: "_bag2",
			albedoColorHex: "#E6BE65",
			target: ["#_item_1_bag_2"],
			action: "focusPoint_2",
		},
		{
			id: "_bag2-blue",
			name: "blue",
			material: "_bag2",
			albedoColorHex: "#6B888C",
			target: ["#_item_1_bag_2"],
			action: "focusPoint_2",
		},
		{
			id: "_bag2-green",
			name: "green",
			material: "_bag2",
			albedoColorHex: "#6F8C5A",
			target: ["#_item_1_bag_2"],
			action: "focusPoint_2",
		},
		{
			id: "_details_1-brown",
			name: "_details_1-brown",
			material: "_details_1",
			albedoColorHex: "#794A2E",
			target: ["#_item_1_details_1.003"],
			action: "start",
		},
	],

	materialGroupsToShow: ["materialGroups2", "materialGroups3"],
	materialGroups: ["materialGroups2", "materialGroups3", "materialGroups4"],
	materialGroups2ActiveGroup: "green",
	materialGroups2: [
		{
			id: "_bag1-blue",
			name: "blue",
			material: "_bag1",
			albedoColorHex: "#6B888C",
			target: ["#_item_1_bag_1"],
			action: "start",
		},
		{
			id: "_bag1-brown",
			name: "brown",
			material: "_bag1",
			albedoColorHex: "#794A2E",
			target: ["#_item_1_bag_1"],
			action: "start",
		},
		{
			id: "_bag1-green",
			name: "green",
			material: "_bag1",
			albedoColorHex: "#6F8C5A",
			target: ["#_item_1_bag_1"],
			action: "start",
		},
	],

	materialGroups3ActiveGroup: "blue",
	materialGroups3: [
		{
			id: "_bag2-yellow",
			name: "yellow",
			material: "_bag2",
			albedoColorHex: "#E6BE65",
			target: ["#_item_1_bag_2"],
			action: "focusPoint_2",
		},
		{
			id: "_bag2-blue",
			name: "blue",
			material: "_bag2",
			albedoColorHex: "#6B888C",
			target: ["#_item_1_bag_2"],
			action: "focusPoint_2",
		},
		{
			id: "_bag2-green",
			name: "green",
			material: "_bag2",
			albedoColorHex: "#6F8C5A",
			target: ["#_item_1_bag_2"],
			action: "focusPoint_2",
		},
	],
	materialGroups4ActiveGroup: "brown",
	materialGroups4: [
		{
			name: "brown",
			material: "_details_1",
			albedoColorHex: "#794A2E",
			target: ["#_item_1_details_1.003"],
			action: "start",
		},
	],

	translate: {
		focusPoint_edge: "Zooma in kant",
		focusPoint_handle: "Handtag",
		focusPoint_outside: "Utsida",
		focusPoint_inside: "Insida",
		materialGroups2: "Ryggsäck",
		materialGroups3: "Fickor",
		white: "Vit",
		green: "Grön",
		red: "Röd",
		blue: "Blå",
		"01_op_deko_2_changeColor": "Spröjs utsida",
		"01_op_deko_2": "Spröjs insida vit",
		window_bars: "Spröjs",
	},
};
