import * as BABYLON from "@babylonjs/core";
import { sceneLoadingSymbole } from "./sceneLoadingSymbole";

export default function sceneSetup(scene, confSetup) {
	const canvas = scene.getEngine().getRenderingCanvas();

	const camPos = confSetup.focusPoints.find((item) => item.name === "focusPoint_default");

	const camera = new BABYLON.ArcRotateCamera(
		"camera1",
		BABYLON.Tools.ToRadians(camPos.alpha), //BABYLON.Tools.ToRadians(-120), // Alpha (rotate Y axis)
		BABYLON.Tools.ToRadians(camPos.beta), //BABYLON.Tools.ToRadians(70), // Beta  (rotate X axis)
		camPos.radius, // Radius (Zoom distanse)
		new BABYLON.Vector3(camPos.position.x, camPos.position.y, camPos.position.z), // TARGET setTarget(BABYLON.Vector3.Zero())
		scene,
	);

	camera.attachControl(canvas, true);
	// camera.viewport = new BABYLON.Viewport(0.1, 0.0, 1, 1.1);

	// CAMERA VIEW SETTINGS
	camera.minZ = 0.1; // VIEW 3D NEARBY
	camera.maxZ = 15; // VIEW 3D FAR DISTANCE
	camera.fov = 0.6; // FIELD OF VIEW
	camera.useAutoRotationBehavior = false; // AUTO ROTATE CAMERA

	camera.useBouncingBehavior = false;

	// camera.input.multiTouchPanAndZoom = false;
	// camera.input.multiTouchPanning = false;
	// RESOLUTION
	// scene.getEngine().setHardwareScalingLevel(0.7);
	scene.clearColor = new BABYLON.Color4(0, 0, 0, 0); // BACKGROUND COLOR AND ALPHA
	//   scene.clearColor = new BABYLON.Color4(1, 1, 1, 1); // BACKGROUND COLOR AND ALPHA
	//   scene.autoClear = false;

	scene.imageProcessingConfiguration.contrast = confSetup.sceneSettings.contrast;
	scene.imageProcessingConfiguration.exposure = confSetup.sceneSettings.exposure;
	// LIGHTS AND REFLECTION
	addEnvironmentToScene(scene, confSetup);

	// shadowGenerator = makeShadow(scene);
	//   addLensFlairLight(scene);
}

async function addEnvironmentToScene(scene, confSetup) {
	// console.log("addEnvironmentToScene");
	const path = process.env.PUBLIC_URL + confSetup.sceneSettings.envPath;
	const file = confSetup.sceneSettings.envActive;
	scene.environmentTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(path + file, scene);

	// console.log(scene);
	const envFile = confSetup.sceneSettings.envPath2 + confSetup.sceneSettings.envActive;

	// console.log(envFile);

	// console.log(require(`./env/photo_studio_01_2k.env`));
	// console.log((() => {require(envFile)})());''
	// const file555 = require( await hej55);

	// scene.environmentTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(evnFile55, scene);

	scene.environmentTexture.rotationY = BABYLON.Tools.ToRadians(
		confSetup.sceneSettings.envdRotationY,
	);
	scene.environmentIntensity = confSetup.sceneSettings.intesity;

}
