import BabylonComponent from "./babylon/BabylonComponent";
import React from "react";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
	let query = useQuery();
	let configurator = query.get("c");

	return (
		<div className='App'>
			<header className='App-header'>
				<BabylonComponent configurator={configurator}></BabylonComponent>
			</header>
		</div>
	);
}

export default App;
