import * as BABYLON from "@babylonjs/core";

export function updateMesh(meshGroupName, mainScene, confSetup) {
	const meshGroup = confSetup.meshGroups.find((meshGroup) => meshGroup.name === meshGroupName);
	if (meshGroup === undefined) console.log("MISSING !!! " + meshGroupName);
	meshGroup.target.map((meshName) => {
		mainScene.meshes.map((mesh) => {
			if (mesh.name.includes("item_")) {
				mesh.isVisible = false; // HIDE ALL ITEMS
				if (mesh.name.includes(meshName)) mesh.isVisible = true; // SHOW SELECTED ITEM
				if (mesh.name.includes("bag") && mesh.material.backFaceCulling)
					createBackFaceMesh({ mesh, mainScene });
			}
		});
	});
}

export function updateMaterial({ active, mainScene, confSetup }) {
	let materialGroup = confSetup.material55.find((item) => item.id === active);

	if (materialGroup === undefined) {
		console.log(active + " - MATERIAL IS MISSING!!! ");
		return;
	}
	// mainScene.meshes.map((mesh) => console.log(mesh.name));
	// console.log("--------------------------------")
	// mainScene.materials.map((material) => console.log(material.name));

	const material = mainScene.getMaterialByName(materialGroup.material);
	if (material === null) {
		// var pbr = new BABYLON.PBRMetallicRoughnessMaterial("pbr", mainScene);
		// pbr.albedoColor = BABYLON.Color3.FromHexString(materialGroup.albedoColorHex);
		console.log("MISSING MATERIAL !!! ")
	} else {

		// checkTexture55(material, "albedoTexture", confSetup) 
		// console.log(material)
		material.albedoColor = BABYLON.Color3.FromHexString(materialGroup.albedoColorHex);
	}

	if (materialGroup.settings !== undefined) {
		for (const key in materialGroup.settings) {
			material[key] = materialGroup.settings[key];
		}
	}
}

function createBackFaceMesh({ mesh, mainScene }) {
	var backSideMesh = mesh.clone(mesh.name + "_Backside");
	var geom = backSideMesh.geometry.copy("testa");
	geom.applyToMesh(backSideMesh);
	backSideMesh.flipFaces(true);

	let backSideMaterial = new BABYLON.StandardMaterial("BacksideMaterial", mainScene);
	backSideMaterial.diffuseColor = BABYLON.Color3.FromHexString("696969");
	backSideMesh.material = backSideMaterial;
}



			// NEW CODE
			// function checkTexture55(material, texture, confSetup) {
			// 	const path2 = confSetup.sceneSettings.babylonFile2.path;
			// 	if (material[texture] !== null) {

	

			// 		const file55 = material[texture].name;

			// 		material[texture] = new BABYLON.Texture(require(`${path2 + file55}`));
			// 		if (file55.includes("shadow")) {
			// 			material.albedoTexture.hasAlpha = true;
			// 			material.useAlphaFromAlbedoTexture = true;
			// 		}
			// 	}
			// };

		
			// for (let index = 0; index < scene.materials.length; index++) {
			// 	const material = scene.materials[index];
	
	
			// 	checkTexture(material, 'reflectionTexture')
			// 	checkTexture(material, "albedoTexture");
			// 	checkTexture(material, "bumpTexture");
			// 	checkTexture(material, "metallicTexture");
			// }
			// NEW CODE