import * as BABYLON from "@babylonjs/core";

export function cameraSettings({ mainScene, cameraSettings }) {
  let camera = mainScene.cameras[0];


  const settings = Object.keys(cameraSettings);


  settings.map((key) => {
    if (key === "viewport") {
 
      const view =  cameraSettings[key]
      camera.viewport = new BABYLON.Viewport(view[0],view[1],view[2],view[3]);
    } else {
   
      key.includes("Alpha") || key.includes("Beta")
        ? (camera[key] = BABYLON.Tools.ToRadians(cameraSettings[key]))
        : (camera[key] = cameraSettings[key]);
    }
  });
}
