
import conf_default from './conf_default'
import {configurator0} from './configurator0?v2'
import conf_screen from './conf_screen?v2'
import conf_cabinet from './conf_cabinet?v2'
import conf_staket from './conf_staket?v2'
import conf_bag from './conf_bag?v2'

export function getConfigurator(configurator) {
    let confSetup
    if (configurator === "0") confSetup = configurator0
    if (configurator === "screen") confSetup =  conf_screen
    if (configurator === "cabinet") confSetup =  conf_cabinet
    if (configurator === "bag") confSetup = conf_bag
    if (configurator === "staket") confSetup = conf_staket

    confSetup.defaultCameraSettings = conf_default.defaultCameraSettings
         
    return confSetup
    
}