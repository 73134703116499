import * as BABYLON from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";

export function sceneLoadingSymbole(scene, isVisibel) {
  // CREATE LOADING SYMBOLE
  const loadingContainer = new GUI.Container();
  loadingContainer.zIndex = 1000;

  const loadingImage = new GUI.Image("but", "./babylon/loading_hstd.png");
  loadingImage.width = "100px";
  loadingImage.height = "100px";
  loadingImage.stretch = GUI.Image.STRETCH_UNIFORM;


  // var loadingText = new GUI.TextBlock();
  // loadingText.text = "Loading 3D...";
  // loadingText.left = +0.5;
  // loadingText.top = "4%";
  // loadingText.color = "#8FA691";
  // loadingText.color = "black";

  
  // loadingContainer.addControl(loadingText);
  loadingContainer.addControl(loadingImage);

  var fullscreenGUI = GUI.AdvancedDynamicTexture.CreateFullscreenUI("FullscreenUI", true);

  fullscreenGUI.addControl(loadingContainer); //Add loading screen to the camera
  
  return [loadingContainer, loadingImage];
}
