import * as BABYLON from "@babylonjs/core";

export function addLight({ light, mainScene }) {
	let material = new BABYLON.StandardMaterial(light.name, mainScene);
	material.diffuseColor = new BABYLON.Color3(0, 0, 0);
	material.alpha = 0.3;

	let lightMesh = BABYLON.MeshBuilder.CreateSphere(light.name, { diameter: 0.05 }, mainScene);
	lightMesh.material = material;
	lightMesh.position = new BABYLON.Vector3(light.position.x, light.position.y, light.position.z);
	lightMesh.isVisible = false;

	addPointerDragBehavior(lightMesh, mainScene);

	let newLight = new BABYLON[light.type](light.name, new BABYLON.Vector3(0, 0, 0), mainScene);
	newLight.parent = lightMesh;
	newLight.intensity = light.intensity;
	newLight.specular = new BABYLON.Color3(light.specular.r, light.specular.g, light.specular.b);
	newLight.diffuse = new BABYLON.Color3(light.diffuse.r, light.diffuse.g, light.diffuse.b);
}

function addPointerDragBehavior(item, mainScene) {
	var pointerDragBehavior = new BABYLON.PointerDragBehavior({
		dragPlaneNormal: new BABYLON.Vector3(0, 1, 0),
	});
	pointerDragBehavior.useObjectOrienationForDragging = false;

	pointerDragBehavior.onDragStartObservable.add((event) => {
		// grab top half to move on y axis, bottom half to move in xz plane.
		if (event.dragPlanePoint.z > item._position.z) {
			pointerDragBehavior._options = { dragAxis: new BABYLON.Vector3(0, 1, 0) };
		}
	});

	pointerDragBehavior.onDragEndObservable.add((event) => {
		pointerDragBehavior._options = { dragPlaneNormal: new BABYLON.Vector3(0, 1, 0) };
	});

	item.addBehavior(pointerDragBehavior);
}

