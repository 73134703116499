// import * as BABYLON from "@babylonjs/core";

import { sceneLoadingSymbole } from "./sceneLoadingSymbole";
import { addGuiNavButtons } from "../func/guiNavButtons";
import { sceneActionHandler } from "../func/sceneActionHandler";
import { updateMesh, updateMaterial } from "../func/updateGraphic";
import { cameraSettings } from "../babylonSetup/cameraSettings";

let firstRenderLoop = true;
let firstSceneIsReady = true;

export const onRender = (scene, confSetup, setState) => {

  	// FRIST RENDER LOOP
	if (firstRenderLoop) {
		firstRenderLoop = false;
    cameraSettings({ mainScene: scene, cameraSettings: confSetup.cameraSettings });
	}

	// FRIST TIME SCENE IS READY
	if (scene.isReady() && firstSceneIsReady) {
		firstSceneIsReady = false;

		scene.meshes.map((mesh) => mesh.name.includes("env") && (mesh.isVisible = true));
    
		updateMesh(confSetup.meshGroupsActiveGroup, scene, confSetup);

		// confSetup.materialGroups.map((type, index) => {
		// 	const active = confSetup[type + "ActiveGroup"];
		// 	updateMaterial({ type, active, mainScene: scene, confSetup });
		// });

		confSetup.material55Default.map((active, index) => {
			// const active = confSetup[type + "ActiveGroup"];
			updateMaterial({ active, mainScene: scene, confSetup });
		});


		if (confSetup.uiSettings.guiNavButtons)
			addGuiNavButtons(confSetup.focusPoints, confSetup, scene, setState);
	}


  
};
