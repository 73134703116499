export default {
  defaultCameraSettings: {
    lowerAlphaLimit: -360 * 2, // ROTATE LIMIT
    upperAlphaLimit: 360 * 2, // ROTATE LIMIT
    lowerBetaLimit: 10, // TILT UP LIMIT
    upperBetaLimit: 95, // TILT DOWN LIMIT
    lowerRadiusLimit: 0.01, // ZOOM IN LIMIT
    upperRadiusLimit: 20, // ZOOM OUT LIMIT
    wheelDeltaPercentage: 0.001, // ZOOM SPEED
    pinchDeltaPercentage: 0.0001, // ZOOM SPEED
    panningSensibility: 0, // PANNING SPEED 0 : off
    panningDistanceLimit: 1,
    minZ : 0.01, // VIEW 3D NEARBY
    maxZ : 20, // VIEW 3D FAR DISTANCE
    fov : 0.6, // FIELD OF VIEW
    useAutoRotationBehavior : false, // AUTO ROTATE CAMERA
    viewport: [0.0, 0.0, 1.0, 1.0] // CENTER VIEW 
  },
  cameraPosition: false,
  path: process.env.PUBLIC_URL + "/babylon/",
};
