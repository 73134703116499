import * as BABYLON from "@babylonjs/core";

const SPEED_RATIO = 2;
const LOOP_MODE = false;
const FROM_FRAME = 0;
const TO_FRAME = 100;

// export function animateMesh2({ meshToAnimate, mainScene, animationData, animation }) {
export function animateMeshPromise({ mainScene, animation, animationArraySuccess }) {
		const meshToAnimate = mainScene.meshes.find((mesh) => mesh.name === animation.target);

		
		if (meshToAnimate === undefined) console.log(animation.target + " - MESH IS MISSING!!!");

		let animationData = animation.animation;
		animationData.map((item) => {
			item.x = -1 * item.x;
			item.y = -1 * item.y;
			item.z = -1 * item.z;
		});




		animationData.map((item, index) => {
	
			const type = `${item.type}`;
			let pos = {
				x: item.x,
				y: item.y,
				z: item.z,
			};

			if (type === "rotation") {
				pos.x = BABYLON.Tools.ToRadians(pos.x);
				pos.y = BABYLON.Tools.ToRadians(pos.y);
				pos.z = BABYLON.Tools.ToRadians(pos.z);
			}

			pos.x = meshToAnimate[type].x + pos.x;
			pos.y = meshToAnimate[type].y + pos.y;
			pos.z = meshToAnimate[type].z + pos.z;

			meshToAnimate.animations.push(
				createAnimation({
					property: `_${type}.x`,
					from: meshToAnimate[type].x,
					to: pos.x,
				}),
				createAnimation({
					property: `_${type}.y`,
					from: meshToAnimate[type].y,
					to: pos.y,
				}),
				createAnimation({
					property: `_${type}.z`,
					from: meshToAnimate[type].z,
					to: pos.z,
				}),
			);
		});

		mainScene.beginAnimation(meshToAnimate, FROM_FRAME, TO_FRAME, LOOP_MODE, SPEED_RATIO, () => {
			animationArraySuccess()
		});

	// });
}

const FRAMES_PER_SECOND = 60;

function createAnimation({ property, from, to }) {
	const ease = new BABYLON.CubicEase();
	ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

	const animation = BABYLON.Animation.CreateAnimation(
		property,
		BABYLON.Animation.ANIMATIONTYPE_FLOAT,
		FRAMES_PER_SECOND,
		ease,
	);
	animation.setKeys([
		{
			frame: 0,
			value: from,
		},
		{
			frame: 100,
			value: to,
		},
	]);

	return animation;
}

// function simplifyRadians(radians) {
// 	const simplifiedRadians = radians % (2 * Math.PI);

// 	return simplifiedRadians < 0
// 		? simplifiedRadians + BABYLON.Tools.ToRadians(360)
// 		: simplifiedRadians;
// }
